<template>
  <div id="Housekeeping">
    <div class="baner">
      <el-carousel
        :interval="4000"
        style="width: 100%"
        arrow="always"
        height="780px"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <el-image
            :class="className"
            fit="cover"
            style="width: 100%; height: 576px"
            :src="
              bannerdd.length !== 0
                ? 'https://gxzw.linjiaxiaoda.com/picture/prod-api' + item.image
                : item.image
            "
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box">
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">家庭保洁</div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="bix-li-one flex ALcenter JCcenter">
          <div
            v-for="(item, index) in list1"
            :key="index"
            class="bix-li-one-mar"
          >
            <div class="flex JCcenter">
              <img :src="item.img" />
            </div>
            <div class="tac fs18 hui333" style="margin-top: 12px">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="bix-li-two">
          <div class="bix-li-two-bg"></div>
          <div class="bix-li-two-box">
            <div class="bix-li-two-box-b">
              <div class="bix-li-two-box-b-o fs16 hui333">
                以家庭服务为应用场景，小达为百万家庭提供综合专业的家庭服务。把时间留给自己，把专业交给小达——打造数字化、线上化、专业化、贴心化的生活服务。
              </div>
              <div class="bix-li-two-box-b-poa"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bix-li">
        <div class="tac fs36 fw6" style="margin-top: 112px">
          保姆/月嫂/育儿嫂
        </div>
        <div class="flex JCcenter box-li-tiao">
          <div class="box-li-tiao-L"></div>
          <div class="box-li-tiao-R"></div>
        </div>
        <div class="bix-li-one flex ALcenter JCcenter">
          <div
            v-for="(item, index) in list2"
            :key="index"
            class="bix-li-one-mar"
          >
            <div class="flex JCcenter">
              <img :src="item.img" />
            </div>
            <div class="tac fs18 hui333" style="margin-top: 12px">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="bix-li-two">
          <div class="bix-li-two-bg2"></div>
          <div class="bix-li-two-box">
            <div class="bix-li-two-box-b">
              <div class="bix-li-two-box-b-o fs16 hui333">
                专业育婴师、育儿嫂、月嫂、早教师、管家、住家保姆、护工等服务，小达家政人员经过严格的培训，可承接各种各样的社会服务。以客户至上的服务态度，为您提供安全可靠的高质量家政服务。
              </div>
              <div class="bix-li-two-box-b-poa"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottombox">
      <div class="bottombox-titile tac fs24 fw6">聚达之家对服务员实行</div>
      <div class="flex JCcenter ALcenter">
        <div
          v-for="(item, index) in list3"
          :key="index"
          class="tac bottombox-li fs18 white"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="tac bottombox-ttext fs16">
        统一岗前培训、统一持证上岗、全面负责对服务员的管理，体验，并聘请专业讲师对服务员进行定期的职业培训，小达以专业便捷的服务解决您的后顾之忧。
      </div>
    </div>
  </div>
</template>

<script>
import { getBanner } from "@/api/new";
export default {
  name: "Housekeeping",
  data() {
    return {
      list1: [
        {
          id: 1,
          name: "数字化",
          img: require("@/assets/Housekeeping1.png"),
        },
        {
          id: 2,
          name: "线上化",
          img: require("@/assets/Housekeeping2.png"),
        },
        {
          id: 3,
          name: "专业化",
          img: require("@/assets/Housekeeping3.png"),
        },
        {
          id: 4,
          name: "贴心化",
          img: require("@/assets/Housekeeping4.png"),
        },
      ],
      list2: [
        {
          id: 5,
          name: "专业至上",
          img: require("@/assets/Housekeeping5.png"),
        },
        {
          id: 6,
          name: "客户至上",
          img: require("@/assets/Housekeeping6.png"),
        },
        {
          id: 7,
          name: "安全可靠",
          img: require("@/assets/Housekeeping7.png"),
        },
        {
          id: 8,
          name: "严格培训",
          img: require("@/assets/Housekeeping8.png"),
        },
      ],
      list3: [
        {
          id: 1,
          name: "不培训不上岗",
        },
        {
          id: 2,
          name: "不查体不进家",
        },
        {
          id: 3,
          name: "不签合同不派工",
        },
      ],
      bannerList: [{ id: 1, image: require("@/assets/xiaodajiazheng.png") }],
      bannerdd: [],
    };
  },
  mounted() {
    this.getbanner();
  },
  methods: {
    getbanner() {
      getBanner({ type: 5 }).then((res) => {
        console.log(res.data, "banner");
        this.bannerdd = res.data;
        if (res.data.length !== 0) {
          this.bannerList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
#Housekeeping {
  .baner {
    width: 1920px;
    height: 576px;
    // background-image: url(~@/assets/xiaodajiazheng.png);
    // background-size: 100% 100%;
  }

  .box {
    width: 1200px;
    margin: 0 360px;
    .bix-li {
      width: 1200px;
      .box-li-tiao {
        width: 1200px;
        height: 6px;
        margin-top: 21px;
        .box-li-tiao-L {
          width: 64px;
          height: 6px;
          background: #f8951d;
          margin-right: 8px;
        }
        .box-li-tiao-R {
          width: 12px;
          height: 6px;
          background: #f8951d;
        }
      }

      .bix-li-one {
        width: 1200px;
        margin: 68px 0 92px;
        .bix-li-one-mar {
          margin-right: 170px;
        }
        .bix-li-one-mar:last-child {
          margin-right: 0;
        }
      }
      .bix-li-two {
        width: 1200px;
        margin-top: 92px;
        position: relative;
        .bix-li-two-bg {
          width: 825px;
          height: 490px;
          background-image: url(~@/assets/Housekeeping9.png);
          background-size: 100% 100%;
        }
        .bix-li-two-bg2 {
          width: 825px;
          height: 490px;
          background-image: url(~@/assets/Housekeeping10.png);
          background-size: 100% 100%;
        }

        .bix-li-two-box {
          position: absolute;
          right: 0;
          top: 95px;
          width: 470px;
          height: 300px;
          background-image: url(~@/assets/Housekeeping11.png);
          background-size: 100% 100%;

          .bix-li-two-box-b {
            width: 470px;
            height: 300px;
            position: relative;
            .bix-li-two-box-b-o {
              width: 365px;
              margin-top: 50px;
              margin-left: 53px;
              line-height: 36px;
            }
            .bix-li-two-box-b-poa {
              position: absolute;
              right: 26px;
              bottom: 63px;
              width: 128px;
              height: 128px;
              background-image: url(~@/assets/Propertyt.png);
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }

  .bottombox {
    width: 1920px;
    height: 386px;
    margin-top: 115px;
    background-image: url(~@/assets/Housekeeping12.png);
    background-size: 100% 100%;
    .bottombox-titile {
      width: 1200px;
      margin: 0 360px;
      height: 148px;
      line-height: 148px;
    }
    .bottombox-li {
      width: 280px;
      height: 60px;
      line-height: 60px;
      background: rgba(248, 149, 29, 0.9);
      border-radius: 0px 0px 0px 0px;
      margin-right: 82px;
    }
    .bottombox-li:last-child {
      margin-right: 0;
    }

    .bottombox-ttext {
      width: 960px;
      line-height: 32px;
      margin: 44px 480px 0;
    }
  }
}
</style>